//form functionality

//variables
var recaptchaForm = document.getElementById("recaptcha");
var subscribeBtn = document.getElementById("subscribe");
var form = document.getElementById("form");
var email = document.getElementById("email");
var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //to check for valid email

subscribeBtn.addEventListener("click", recaptchaAppear);

function recaptchaAppear() {
    //email is valid -> rechaptcha appears
    if (regex.test(email.value)) {
        form.classList.remove("error-animation");
        form.classList.add("recaptcha-pass");
        recaptchaForm.classList.remove("hiderecaptcha");
        console.log("recap appear")
        //email is invalid -> string does not get submitted and error message appears telling user to fix email
    } else {
        form.classList.remove("success-animation");
        form.classList.add("error-animation");
        console.log("invalid email")
    }
}

//when recaptcha is checked
window.callback = callback;
function callback (response){
    if (response) {
        var formData = new FormData(); //collect submitted email value
        formData.append('email', document.querySelector('input[name="email"]').value)
        formData.append('g-recaptcha-response',response)

        fetch("https://getform.io/f/86cba2ba-668b-4802-8a5f-65dfa47f8de9",
        {
            method: "POST",
            body: formData
        })
        .then(response => {
            form.classList.remove("error-animation");
            recaptchaForm.classList.add("hiderecaptcha");
            form.classList.add("success-animation");
        })
        .catch(error => console.log(error)) //any errors in fetch function will print in console.log
    }
}